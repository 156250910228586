import { call } from "../utils/apiCall";
import axios from "axios";
import { API_URL } from "app/constants";

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

class AuthApi {
  login(loginData) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call("post", "api/v1/admin/login", null, loginData);
          if (res.data.success === true) {
            const accessToken = res?.data?.token;
            setSession(accessToken);
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  forgotPassword(email) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call("post", "api/v1/admin/send-otp", null, email);
          console.log(res.data);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  otpPassword(data) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call("post", "api/v1/admin/verify-otp", null, data);
          console.log(res.data);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  resetPassword(data, token) {
    console.log(token);
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await axios({
            method: "post",
            url: API_URL + "api/v1/admin/reset-password",
            params: {},
            data: data,
            headers: {
              "Content-Type": "application/json",
              "Accept-Language": "en",
              authorization: `Bearer ${token}`,
            },
            responseType: "json",
          });
          console.log(res.data);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
}

export const authApi = new AuthApi();
