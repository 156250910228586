import {
  Card,
  Grid,
  styled,
  useTheme,
  MenuItem,
  Select,
  Box,
  Button,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Fragment } from "react";
import PaginationTable from "./components/userTable";
import { useEffect, useState } from "react";
import { call } from "../../utils/apiCall";
import SearchIcon from "@mui/icons-material/Search";
import AddUserModal from "./components/AddUserModal";
import AlertDialog from "./components/Alert";
import PasswordResetModal from "./components/ResetPassword";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAuth from "app/hooks/useAuth";
import { getRoleAndpermission } from "app/utils/utils";

const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
}));

const CardHeader = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  marginRight: ".5rem",
  textTransform: "capitalize",
}));

const SubTitle = styled("span")(({ theme }) => ({
  fontSize: "1rem",
  color: theme.palette.text.secondary,
}));

const H2 = styled("h2")(({ theme }) => ({
  fontSize: "24px",
  fontWeight: "600",
  color: "01052D",
  marginTop: "0px",
  marginBottom: "30px",
}));

const ManageFaq = () => {
  const { palette } = useTheme();
  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addUserModal, setAddUserModal] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [alertDeleteModal, setAlertDeleteModal] = useState(false);
  const [resetPassModal, setResetPassModal] = useState(false);
  const [totalItems, setTotalItems] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [skipData, setSkipData] = useState(0);
  const [searchKey, setSearchKey] = useState();
  const [userId, setUserId] = useState();
  const [userStatus, setUserStatus] = useState();
  const [sortData, setSortData] = useState("asc");
  const [targetData, setTargetData] = useState("");
  const [action, setAction] = useState("");

  useEffect(() => {
    getFaqData();
  }, [searchKey, skipData, rowsPerPage, sortData, addUserModal]);

  const getFaqData = () => {
    (async () => {
      try {
        const res = await call(
          "get",
          "api/v1/admin/faqs",
          searchKey
            ? {
                search: searchKey,
                limit: rowsPerPage,
                skip: skipData,
                sort: sortData,
              }
            : { limit: rowsPerPage, skip: skipData, sort: sortData },
          null
        );
        setUsersData(res.data.data ? res.data.data : []);
        setTotalItems(res.data.totalItems ? res.data.totalItems : 0);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setUsersData([]);
      }
    })();
  };

  const deleteUser = async (id) => {
    try {
      const res = await call(
        "delete",
        `api/v1/admin/delete-faq/${id}`,
        null,
        null
      );
      setLoading(false);
      getFaqData();
      // let letnewData = usersData.filter((item) => item.id !== id);
      // setUsersData(letnewData);
      setAlertDeleteModal(!alertDeleteModal);
      toast.success("FAQ Deleted Successfully!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const blockUser = async (id) => {
    let data = {
      status: userStatus === "block" ? false : true,
    };
    try {
      const res = await call("patch", `api/v1/admin/user/${id}`, null, data);
      setLoading(false);
      getFaqData();
      setAlertModal(!alertModal);
      toast.success(
        userStatus === "block"
          ? "User Block Successfully!"
          : "User Active Successfully!",
        {
          position: toast.POSITION.BOTTOM_LEFT,
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const { roleAndpermission } = useAuth();

  return (
    <Fragment>
      <ContentBox className="analytics">
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <H2>Manage FAQs</H2>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <Button
                  disabled={
                    !getRoleAndpermission(
                      roleAndpermission,
                      "FAQ Management",
                      "add"
                    )
                  }
                  variant="contained"
                  size="small"
                  onClick={() => {
                    setAddUserModal(!addUserModal);
                    setAction("add");
                  }}
                  sx={{ px: 3, py: 0.9, backgroundColor: "#0432A3" }}
                >
                  + Add FAQ
                </Button>
              </div>
              <div style={{ marginRight: "20px" }}>
                <TextField
                  id="search"
                  type="search"
                  // label="Search"
                  placeholder="Search..."
                  size="small"
                  value={searchKey}
                  onChange={(e) => setSearchKey(e.target.value)}
                  // sx={{ width: '50%' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            <div style={{ marginTop: "20px" }}>
              <PaginationTable
                data={usersData}
                alertModal={alertModal}
                setAlertModal={setAlertModal}
                alertDeleteModal={alertDeleteModal}
                setAlertDeleteModal={setAlertDeleteModal}
                addUserModal={addUserModal}
                setAddUserModal={setAddUserModal}
                resetPassModal={resetPassModal}
                setResetPassModal={setResetPassModal}
                totalItems={totalItems}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                setSkipData={setSkipData}
                setUserId={setUserId}
                userStatus={userStatus}
                setUserStatus={setUserStatus}
                sortData={sortData}
                setSortData={setSortData}
                setTargetData={setTargetData}
                setAction={setAction}
              />
            </div>
          </Grid>
        </Grid>
      </ContentBox>
      {addUserModal && (
        <AddUserModal
          modal={addUserModal}
          action={action}
          data={targetData}
          toggle={() => setAddUserModal(!addUserModal)}
        />
      )}

      {alertModal && (
        <AlertDialog
          modal={alertModal}
          toggle={() => setAlertModal(!alertModal)}
          confirmFunc={() => blockUser(userId)}
          title={
            userStatus === "block"
              ? "Block User Confirmation"
              : "Activate User Confirmation"
          }
          description={
            userStatus === "block"
              ? "Are you sure to block the user and its permissions?"
              : "Are you sure to activate user and its permissions?"
          }
        />
      )}

      {alertDeleteModal && (
        <AlertDialog
          modal={alertDeleteModal}
          toggle={() => setAlertDeleteModal(!alertDeleteModal)}
          confirmFunc={() => deleteUser(userId)}
          title="Delete FAQ Confirmation"
          description="Are you want to sure delete the FAQ?"
        />
      )}

      {resetPassModal && (
        <PasswordResetModal
          modal={resetPassModal}
          toggle={() => setResetPassModal(!resetPassModal)}
          userId={userId}
        />
      )}
      <ToastContainer />
    </Fragment>
  );
};

export default ManageFaq;
