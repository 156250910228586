import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Checkbox,
} from "@mui/material";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import moment from "moment";
import useAuth from "app/hooks/useAuth";
import { getRoleAndpermission } from "app/utils/utils";

const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
  "& .MuiTableCell-root": {
    borderBottom: "1px solid #D1DFFF",
  },
  "& .MuiTableRow-root:nth-child(even)": {
    backgroundColor: "#f2f2f2", // Alternate row color
  },
}));

const StyledTableHead = styled(TableCell)(() => ({
  fontSize: "15px",
  fontWeight: 600,
  color: "white",
  backgroundColor: "rgba(39, 75, 175, 0.8)",
  "&:first-of-type": {
    borderTopLeftRadius: "15px", // Curved edge on the top-left corner
  },
  "&:last-of-type": {
    borderTopRightRadius: "15px", // Curved edge on the top-right corner
  },
}));

const StyledTableCell = styled(TableCell)(() => ({
  color: `#676A79`,
}));

const PaginationTable = ({
  data,
  alertModal,
  setAlertModal,
  alertDeleteModal,
  setAlertDeleteModal,
  addUserModal,
  setAddUserModal,
  resetPassModal,
  setResetPassModal,
  totalItems,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  setSkipData,
  setUserId,
  userStatus,
  setUserStatus,
  setSortData,
  sortData,
  setTargetData,
  setAction,
}) => {
  const handleChangePage = (_, newPage) => {
    setSkipData(newPage * rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setSkipData(0);
  };

  console.log(data);

  const { roleAndPermission } = useAuth();

  return (
    <Box width="100%" overflow="auto">
      <StyledTable>
        <TableHead>
          <TableRow>
            <StyledTableHead
              align="center"
              style={{
                overflowX: "auto",
                wordBreak: "break-word",
                width: "250px",
                whiteSpace: "nowrap",
              }}
            >
              Question
            </StyledTableHead>
            <StyledTableHead
              autoCapitalize="none"
              align="center"
              style={{
                overflowX: "auto",
                wordBreak: "break-word",
                width: "250px",
              }}
            >
              Answer
            </StyledTableHead>
            <StyledTableHead align="center">Is Popular</StyledTableHead>
            <StyledTableHead align="center">
              Created On
              {sortData === "desc" ? (
                <IconButton onClick={() => setSortData("asc")}>
                  <Icon sx={{ color: "white" }}>
                    <Tooltip title="Sort Date">
                      <ArrowDownwardIcon />
                    </Tooltip>
                  </Icon>
                </IconButton>
              ) : (
                <IconButton onClick={() => setSortData("desc")}>
                  <Icon sx={{ color: "white" }}>
                    <Tooltip title="Sort Date">
                      <ArrowUpwardIcon />
                    </Tooltip>
                  </Icon>
                </IconButton>
              )}
            </StyledTableHead>
            <StyledTableHead align="center">Updated On</StyledTableHead>
            <StyledTableHead align="center">Action</StyledTableHead>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((user, index) => (
            <TableRow key={index}>
              <StyledTableCell
                align="center"
                style={{
                  overflowX: "auto",
                  wordBreak: "break-word",
                  width: "250px",
                  whiteSpace: "nowrap",
                }}
              >
                {user.question}
              </StyledTableCell>
              <StyledTableCell
                autoCapitalize="none"
                align="center"
                style={{
                  overflowX: "auto",
                  wordBreak: "break-word",
                  width: "250px",
                  whiteSpace: "nowrap",
                }}
              >
                {user.answer}
              </StyledTableCell>
              <StyledTableCell align="center">
                <Checkbox
                  title="hello"
                  label="hello"
                  name="add"
                  checked={user.is_popular}
                  disabled={true}
                ></Checkbox>
              </StyledTableCell>
              <StyledTableCell align="center">
                {moment(user.createdAt).format("ll")}
              </StyledTableCell>
              <StyledTableCell align="center">
                {moment(user.updatedAt).format("ll")}
              </StyledTableCell>
              <StyledTableCell align="center">
                <IconButton
                  disabled={
                    !getRoleAndpermission(
                      roleAndPermission,
                      "FAQ Management",
                      "edit"
                    )
                  }
                  onClick={() => {
                    setTargetData(user);
                    setAction("edit");
                    setAddUserModal(true);
                  }}
                >
                  <Tooltip title="Edit">
                    <CreateIcon />
                  </Tooltip>
                </IconButton>
                <IconButton
                  disabled={
                    !getRoleAndpermission(
                      roleAndPermission,
                      "FAQ Management",
                      "delete"
                    )
                  }
                  onClick={() => {
                    setAlertDeleteModal(!alertDeleteModal);
                    setUserId(user.id);
                  }}
                >
                  <Tooltip title="Delete">
                    <DeleteIcon />
                  </Tooltip>
                </IconButton>
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>

      <TablePagination
        sx={{ px: 2 }}
        page={page}
        component="div"
        rowsPerPage={rowsPerPage}
        count={totalItems}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[5, 10, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{ "aria-label": "Next Page" }}
        backIconButtonProps={{ "aria-label": "Previous Page" }}
      />
    </Box>
  );
};

export default PaginationTable;
