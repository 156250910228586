import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CreateIcon from "@mui/icons-material/Create";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import useAuth from "app/hooks/useAuth";
import { getRoleAndpermission } from "app/utils/utils";

const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
  "& .MuiTableCell-root": {
    borderBottom: "1px solid #D1DFFF",
  },
  "& .MuiTableRow-root:nth-child(even)": {
    backgroundColor: "#f2f2f2", // Alternate row color
  },
}));

const StyledTableHead = styled(TableCell)(() => ({
  fontSize: "15px",
  fontWeight: 600,
  color: "white",
  backgroundColor: "rgba(39, 75, 175, 0.8)",
  wordBreak: "break-word",
  "&:first-of-type": {
    borderTopLeftRadius: "15px", // Curved edge on the top-left corner
  },
  "&:last-of-type": {
    borderTopRightRadius: "15px", // Curved edge on the top-right corner
  },
}));

const StyledTableCell = styled(TableCell)(() => ({
  color: `#676A79`,
  autoCapitalize: "none",
  wordBreak: "break-word",
}));

const PaginationTable = ({
  data,
  alertModal,
  setAlertModal,
  alertDeleteModal,
  setAlertDeleteModal,
  addUserModal,
  setAddUserModal,
  resetPassModal,
  setResetPassModal,
  totalItems,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  setSkipData,
  setUserId,
  userStatus,
  setUserStatus,
  setSortData,
  sortData,
  setTargetData,
  setAction,
}) => {
  const navigate = useNavigate();

  const handleChangePage = (_, newPage) => {
    setSkipData(newPage * rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setSkipData(0);
  };

  console.log(data);

  const { roleAndPermission } = useAuth();

  return (
    <Box width="100%" overflow="auto">
      <StyledTable>
        <TableHead>
          <TableRow>
            <StyledTableHead
              align="left"
              style={{ width: "50px", paddingLeft: "20px" }}
            >
              ID
            </StyledTableHead>
            <StyledTableHead autoCapitalize="none" align="center">
              Name
            </StyledTableHead>
            <StyledTableHead
              autoCapitalize="none"
              align="center"
              style={{
                overflowX: "auto",
                wordBreak: "break-word",
                width: "250px",
                whiteSpace: "nowrap",
              }}
            >
              Description
            </StyledTableHead>
            <StyledTableHead
              autoCapitalize="none"
              align="center"
              style={{ paddingLeft: "50px" }}
            >
              Category-wise Price
            </StyledTableHead>
            <StyledTableHead align="center">
              Created Date
              {sortData === "desc" ? (
                <IconButton onClick={() => setSortData("asc")}>
                  <Icon sx={{ color: "white" }}>
                    <Tooltip title="Sort Date">
                      <ArrowDownwardIcon />
                    </Tooltip>
                  </Icon>
                </IconButton>
              ) : (
                <IconButton onClick={() => setSortData("desc")}>
                  <Icon sx={{ color: "white" }}>
                    <Tooltip title="Sort Date">
                      <ArrowUpwardIcon />
                    </Tooltip>
                  </Icon>
                </IconButton>
              )}
            </StyledTableHead>
            <StyledTableHead align="center">Action</StyledTableHead>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((user, index) => (
            <TableRow key={index}>
              <StyledTableCell
                align="left"
                style={{ width: "50px", paddingLeft: "20px" }}
              >
                {user.membershipId}
              </StyledTableCell>
              <StyledTableCell autoCapitalize="none" align="center">
                {user.name}
              </StyledTableCell>
              <StyledTableCell
                autoCapitalize="none"
                align="center"
                style={{
                  overflowX: "auto",
                  wordBreak: "break-word",
                  width: "250px",
                  whiteSpace: "nowrap",
                }}
              >
                {user.description}
              </StyledTableCell>
              <StyledTableCell
                autoCapitalize="none"
                align="center"
                style={{ paddingLeft: "50px" }}
              >
                {user.category_wise_value}
              </StyledTableCell>
              <StyledTableCell align="center">
                {moment(user.createdAt).format("ll")}
              </StyledTableCell>
              <StyledTableCell align="center">
                <IconButton
                  disabled={
                    !getRoleAndpermission(
                      roleAndPermission,
                      "Subscription Management",
                      "edit"
                    )
                  }
                  onClick={() =>
                    navigate(
                      `/manage-subscription/edit-membership/${user.membershipId}`
                    )
                  }
                >
                  <Tooltip title="Edit">
                    <CreateIcon />
                  </Tooltip>
                </IconButton>
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>

      <TablePagination
        sx={{ px: 2 }}
        page={page}
        component="div"
        rowsPerPage={rowsPerPage}
        count={totalItems}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[5, 10, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{ "aria-label": "Next Page" }}
        backIconButtonProps={{ "aria-label": "Previous Page" }}
      />
    </Box>
  );
};

export default PaginationTable;
