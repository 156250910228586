import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Create from "@mui/icons-material/Create";
import BlockIcon from "@mui/icons-material/Block";
import moment from "moment";
import useAuth from "app/hooks/useAuth";
import { getRoleAndpermission } from "app/utils/utils";

const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0 } },
  },
  "& .MuiTableCell-root": {
    borderBottom: "1px solid #D1DFFF",
  },
  "& .MuiTableRow-root:nth-child(even)": {
    backgroundColor: "#f2f2f2", // Alternate row color
  },
}));

const StyledTableHead = styled(TableCell)(() => ({
  fontSize: "15px",
  fontWeight: 600,
  color: "white",
  backgroundColor: "rgba(39, 75, 175, 0.8)",
  "&:first-of-type": {
    borderTopLeftRadius: "15px", // Curved edge on the top-left corner
  },
  "&:last-of-type": {
    borderTopRightRadius: "15px", // Curved edge on the top-right corner
  },
}));

const StyledTableCell = styled(TableCell)(() => ({
  color: `#676A79`,
}));

const PaginationTable = ({
  data,
  alertModal,
  setAlertModal,
  alertDeleteModal,
  setAlertDeleteModal,
  addUserModal,
  setAddUserModal,
  resetPassModal,
  setResetPassModal,
  totalItems,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  setSkipData,
  setUserId,
  userStatus,
  setUserStatus,
  setSortData,
  sortData,
}) => {
  const handleChangePage = (_, newPage) => {
    setSkipData(newPage * rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setSkipData(0);
  };

  console.log(data);
  const { roleAndPermission } = useAuth();

  return (
    <Box width="100%" overflow="auto">
      <StyledTable
        style={{ width: "2000px", overflowX: "auto", wordBreak: "break-word" }}
      >
        <TableHead>
          <TableRow>
            <StyledTableHead
              align="left"
              style={{ width: "100px", paddingLeft: "40px" }}
            >
              ID
            </StyledTableHead>
            <StyledTableHead align="center">Full Name</StyledTableHead>
            <StyledTableHead align="center" style={{ width: "300px" }}>
              Email
            </StyledTableHead>
            <StyledTableHead align="center">Support Type</StyledTableHead>
            <StyledTableHead align="center">Subject</StyledTableHead>
            <StyledTableHead align="center">Message</StyledTableHead>
            <StyledTableHead align="center">Page Link</StyledTableHead>
            <StyledTableHead align="center">Device Type</StyledTableHead>
            <StyledTableHead align="center">Media</StyledTableHead>
            <StyledTableHead align="center">Status</StyledTableHead>
            <StyledTableHead align="center">
              Created Date
              {sortData === "desc" ? (
                <IconButton onClick={() => setSortData("asc")}>
                  <Icon sx={{ color: "white" }}>
                    <Tooltip title="Sort Date">
                      <ArrowDownwardIcon />
                    </Tooltip>
                  </Icon>
                </IconButton>
              ) : (
                <IconButton onClick={() => setSortData("desc")}>
                  <Icon sx={{ color: "white" }}>
                    <Tooltip title="Sort Date">
                      <ArrowUpwardIcon />
                    </Tooltip>
                  </Icon>
                </IconButton>
              )}
            </StyledTableHead>

            <StyledTableHead align="center">Action</StyledTableHead>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((user, index) => (
            <TableRow key={index}>
              <StyledTableCell align="left" style={{ paddingLeft: "40px" }}>
                {user.id}
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: "20px" }}>
                {user.full_name}
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: "100px" }}>
                {user.email}
              </StyledTableCell>
              <StyledTableCell align="center">
                {user.support_type}
              </StyledTableCell>
              <StyledTableCell align="center">{user.subject}</StyledTableCell>
              <StyledTableCell align="center">{user.message}</StyledTableCell>
              <StyledTableCell align="center">{user.page_link}</StyledTableCell>
              <StyledTableCell align="center">
                {user.device_type}
              </StyledTableCell>
              <StyledTableCell align="center">{user.media}</StyledTableCell>
              <StyledTableCell align="center">{user.status}</StyledTableCell>
              <StyledTableCell align="center">
                {moment(user.createdAt).format("ll")}
              </StyledTableCell>
              <StyledTableCell align="center">
                <IconButton
                  disabled={
                    !getRoleAndpermission(
                      roleAndPermission,
                      "Support Management",
                      "edit"
                    )
                  }
                  onClick={() => {
                    setResetPassModal(!resetPassModal);
                    setUserId(user.id);
                  }}
                >
                  <Tooltip title="Update Status">
                    <Create />
                  </Tooltip>
                </IconButton>
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>

      <TablePagination
        sx={{ px: 2 }}
        page={page}
        component="div"
        rowsPerPage={rowsPerPage}
        count={totalItems}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[5, 10, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{ "aria-label": "Next Page" }}
        backIconButtonProps={{ "aria-label": "Previous Page" }}
      />
    </Box>
  );
};

export default PaginationTable;
