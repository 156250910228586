import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import PaidIcon from "@mui/icons-material/Paid";
import useAuth from "app/hooks/useAuth";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getRoleAndpermission } from "app/utils/utils";

const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0 } },
  },
  "& .MuiTableRow-root:nth-child(even)": {
    backgroundColor: "#f2f2f2", // Alternate row color
  },
}));

const StyledTableHead = styled(TableCell)(() => ({
  fontSize: "15px",
  fontWeight: 600,
  color: "white",
  backgroundColor: "rgba(39, 75, 175, 0.8)",
  "&:first-of-type": {
    borderTopLeftRadius: "15px", // Curved edge on the top-left corner
  },
  "&:last-of-type": {
    borderTopRightRadius: "15px", // Curved edge on the top-right corner
  },
}));

const StyledTableCell = styled(TableCell)(() => ({
  color: `#676A79`,
}));

const PaginationTable = ({
  data,
  // alertModal,
  // setAlertModal,
  // alertDeleteModal,
  // setAlertDeleteModal,
  // setEditCompanyModal,
  // editCompanyModal,
  // setEditCompanyData,
  totalItems,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  setSkipData,
  setCompanyId,
}) => {
  const handleChangePage = (_, newPage) => {
    setSkipData(newPage * rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setSkipData(0);
  };

  const { roleAndPermission } = useAuth();

  return (
    <Box width="100%" overflow="auto">
      <StyledTable>
        <TableHead>
          <TableRow>
            <StyledTableHead align="left" style={{ paddingLeft: "20px" }}>
              Wallet Id.
            </StyledTableHead>
            <StyledTableHead align="left">User Name</StyledTableHead>
            <StyledTableHead align="left">Email</StyledTableHead>
            <StyledTableHead align="left">Created Date</StyledTableHead>
            <StyledTableHead align="left">Amount</StyledTableHead>
            <StyledTableHead align="left">Status</StyledTableHead>
            <StyledTableHead align="left">Date</StyledTableHead>
            <StyledTableHead align="left">Action</StyledTableHead>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((company, index) => (
            <TableRow key={index}>
              <StyledTableCell align="left" style={{ paddingLeft: "20px" }}>
                {index + 1}
              </StyledTableCell>
              <StyledTableCell align="left">Nobel Page User</StyledTableCell>
              <StyledTableCell align="left">test@yopmail.com</StyledTableCell>
              <StyledTableCell align="left">25/12/2023</StyledTableCell>
              <StyledTableCell align="left">$400</StyledTableCell>
              <StyledTableCell align="left">Successful</StyledTableCell>
              <StyledTableCell align="left">26 July 2024</StyledTableCell>
              <StyledTableCell align="left">
                {/* <Typography variant="span" color="primary" sx={{ cursor: 'pointer' }}>
                  View
                </Typography> */}
                {/* <IconButton
                    onClick={() => {
                      setAlertModal(!alertModal);
                      setCompanyId(company.id);
                    }}
                  >
                    <Icon color="error">close</Icon>
                    <BlockIcon />
                  </IconButton> */}
                {/* <IconButton>
                  <EditIcon />
                </IconButton> */}
                {/* <IconButton
                    onClick={() => {
                      setEditCompanyModal(!editCompanyModal);
                      setEditCompanyData(company);
                    }}
                  >
                    <Tooltip title="Edit Company">
                      <EditIcon />
                    </Tooltip>
                  </IconButton>
   */}
                {/* <IconButton
                onClick={() => {

                  setAlertDeleteModal(!alertDeleteModal);
                  setCompanyId(company.id);
                }}
                >
                  {' '}
                  <Tooltip title="View Transactions">
                    <PaidIcon />
                  </Tooltip>
                </IconButton> */}

                <IconButton
                  // onClick={() => {
                  //   setAlertDeleteModal(!alertDeleteModal);
                  //   setCompanyId(company.id);
                  // }}

                  disabled={
                    !getRoleAndpermission(
                      roleAndPermission,
                      "Wallet Management",
                      "view"
                    )
                  }
                >
                  <Tooltip title="View">
                    <VisibilityIcon />
                  </Tooltip>
                </IconButton>
                {/* <Typography
                  variant="span"
                  color="primary"
                  sx={{ cursor: "pointer" }}
                >
                  View
                </Typography> */}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>

      <TablePagination
        sx={{ px: 2 }}
        page={page}
        component="div"
        rowsPerPage={rowsPerPage}
        count={totalItems}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[5, 10, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{ "aria-label": "Next Page" }}
        backIconButtonProps={{ "aria-label": "Previous Page" }}
      />
    </Box>
  );
};

export default PaginationTable;
