import * as React from "react";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";
import { useTheme, TextField, InputAdornment, Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { call } from "../../../utils/apiCall";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import noImg from './../img/no-profile-picture-icon.svg'
import {
  PhoneNumberUtil,
  // using PNF alias to follow along with documentaion
  PhoneNumberFormat as PNF,
} from "google-libphonenumber";
import { useEffect } from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const styles = styled(TextField)({
  "*.Mui-focused": {
    borderColor: "transparent",
    outline: "none",
  },
});

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function AddUserModal(props) {
  const { modal, toggle, data, action } = props;
  const { palette } = useTheme();
  const textError = palette.error.main;

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneCount, setPhoneCount] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorEmail, setErrorEmail] = useState("");
  const [errorFirstName, setErrorFirstName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorPhoneNumber, setErrorPhoneNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [rolesData, setRolesData] = useState([]);
  const [selectedRole, setSelectedRole] = useState(0);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const phoneUtil = PhoneNumberUtil.getInstance();

  const phoneCountValidation = () => {
    let num = JSON.stringify(phoneNumber);
    let code = JSON.stringify(phoneCount);
    const number = phoneUtil.parse(num, code);

    //console.log(phoneUtil.isValidNumber(number));
    return phoneUtil.isValidNumber(number);
  };

  const checkSignUpValidation = () => {
    let emailValid =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let stringName = /^[a-zA-Z\s._]+$/;
    let stringNameLast = /^[a-zA-Z\s._]+$/;
    let passwordValid =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!""#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!""#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/;

    let formIsValid = true;

    if (!email) {
      setErrorEmail("Please enter your email.");
      formIsValid = false;
    } else if (!emailValid.test(email)) {
      setErrorEmail("Please enter your email in a valid format.");
      formIsValid = false;
    } else {
      setErrorEmail("");
    }

    if (!firstName) {
      setErrorFirstName("Enter your first name.");
      formIsValid = false;
    } else if (!stringName.test(firstName)) {
      setErrorFirstName("First name is invalid.");
      formIsValid = false;
    } else if (firstName.length > 50) {
      setErrorFirstName("Max 50 characters allowed.");
      formIsValid = false;
    } else {
      setErrorFirstName("");
    }

    if (!lastName) {
      setErrorLastName("Enter your last name.");
      formIsValid = false;
    } else if (!stringNameLast.test(lastName)) {
      setErrorLastName("Last name is invalid.");
      formIsValid = false;
    } else if (lastName.length > 50) {
      setErrorLastName("Max 50 characters allowed.");
      formIsValid = false;
    } else {
      setErrorLastName("");
    }

    if (!password) {
      setErrorPassword("Please enter your password");
      formIsValid = false;
    } else if (!passwordValid.test(password)) {
      setErrorPassword(
        'The password should be minimum 8 characters long and contain at least one : Upper and Lower Case Alphabet, Number, Special Character. The special characters that can be used are [!""#$%&\'()*+,-./:;<=>?@[]^_`{|}~]'
      );
      formIsValid = false;
    } else {
      setErrorPassword("");
    }

    if (phoneNumber === 0) {
      setErrorPhoneNumber("Please enter the phone number.");
      formIsValid = false;
    } else if (phoneNumber.length < 10) {
      setErrorPhoneNumber("Please enter the valid number.");
      formIsValid = false;
    } else if (!phoneCountValidation()) {
      setErrorPhoneNumber("Please enter the valid number.");
      formIsValid = false;
    }

    // else if (phoneNumber) {
    //   phoneExist(phoneNumber).then((response) => {
    //     if (response === false) {
    //       setErrorPhoneNumber('Phone already exist.');
    //       formIsValid = false;
    //       setPhoneExistence(false);
    //     }

    //     else {
    //       setErrorPhoneNumber('');
    //       setPhoneExistence(true);
    //     }
    //   });
    // }
    else {
      setErrorPhoneNumber("");
    }

    return formIsValid;
  };

  const checkEditValidation = () => {
    let emailValid =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let stringName = /^[a-zA-Z\s._]+$/;
    let stringNameLast = /^[a-zA-Z\s._]+$/;
    let passwordValid =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!""#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!""#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/;

    let formIsValid = true;

    if (!email) {
      setErrorEmail("Please enter your email.");
      formIsValid = false;
    } else if (!emailValid.test(email)) {
      setErrorEmail("Please enter your email in a valid format.");
      formIsValid = false;
    } else {
      setErrorEmail("");
    }

    if (!firstName) {
      setErrorFirstName("Enter your first name.");
      formIsValid = false;
    } else if (!stringName.test(firstName)) {
      setErrorFirstName("First name is invalid.");
      formIsValid = false;
    } else if (firstName.length > 50) {
      setErrorFirstName("Max 50 characters allowed.");
      formIsValid = false;
    } else {
      setErrorFirstName("");
    }

    if (!lastName) {
      setErrorLastName("Enter your last name.");
      formIsValid = false;
    } else if (!stringNameLast.test(lastName)) {
      setErrorLastName("Last name is invalid.");
      formIsValid = false;
    } else if (lastName.length > 50) {
      setErrorLastName("Max 50 characters allowed.");
      formIsValid = false;
    } else {
      setErrorLastName("");
    }

    if (password !== "" && !passwordValid.test(password)) {
      setErrorPassword(
        'The password should be minimum 8 characters long and contain at least one : Upper and Lower Case Alphabet, Number, Special Character. The special characters that can be used are [!""#$%&\'()*+,-./:;<=>?@[]^_`{|}~]'
      );
      formIsValid = false;
    } else {
      setErrorPassword("");
    }

    if (phoneNumber === 0) {
      setErrorPhoneNumber("Please enter the phone number.");
      formIsValid = false;
    } else if (phoneNumber.length < 10) {
      setErrorPhoneNumber("Please enter the valid number.");
      formIsValid = false;
    } else if (!phoneCountValidation()) {
      setErrorPhoneNumber("Please enter the valid number.");
      formIsValid = false;
    }

    // else if (phoneNumber) {
    //   phoneExist(phoneNumber).then((response) => {
    //     if (response === false) {
    //       setErrorPhoneNumber('Phone already exist.');
    //       formIsValid = false;
    //       setPhoneExistence(false);
    //     }

    //     else {
    //       setErrorPhoneNumber('');
    //       setPhoneExistence(true);
    //     }
    //   });
    // }
    else {
      setErrorPhoneNumber("");
    }

    return formIsValid;
  };

  const handleSubmit = async () => {
    console.log(checkSignUpValidation());
    if (checkSignUpValidation()) {
      let data = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
        phone: phoneNumber,
        registration_type: "email",
        role_id: selectedRole,
      };
      console.log(data);
      try {
        const res = await call("post", "api/v1/admin/add-admin", null, data);
        // setLoading(false);
        toggle();
        toast.success("User Created Successfully!", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      } catch (err) {
        console.log(err);
        setErrorMessage(err.response.data.message);
      }
    }
  };

  const handleEditSubmit = async () => {
    if (checkEditValidation()) {
      let bodyData = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone: phoneNumber,
        registration_type: "email",
        role_id: selectedRole,
        userId: data.id,
      };

      if (password !== "") {
        bodyData["password"] = password;
      }

      try {
        const res = await call(
          "patch",
          "api/v1/admin/edit-admin",
          null,
          bodyData
        );
        // setLoading(false);
        toggle();
        toast.success("User updated Successfully!", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      } catch (err) {
        console.log(err);
        setErrorMessage(err.response.data.message);
      }
    }
  };

  const getRoles = () => {
    (async () => {
      try {
        const res = await call(
          "get",
          "api/v1/admin/roles",
          { limit: 1, skip: 0, sort: "asc", pagination: 0 },
          null
        );
        setRolesData(res.data.data ? res.data.data : []);
      } catch (err) {
        console.log(err);
        setRolesData([]);
      }
    })();
  };

  useEffect(() => {
    if (action == "view" || action == "edit") {
      setFirstName(data.first_name);
      setLastName(data.last_name);
      setEmail(data.email);
      setPhoneNumber(data.phone);
      setSelectedRole(data.role.id);
    }
    getRoles();
  }, [data, action]);

  return (
    <div>
      <BootstrapDialog
        width={"lg"}
        aria-labelledby="customized-dialog-title"
        open={modal}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={toggle}>
          {action === "add"
            ? "Add new user"
            : action == "view"
            ? "View User"
            : "Edit User"}
        </BootstrapDialogTitle>
        <DialogContent>
          <form>
            <Grid container spacing={3}>
              <Grid item>
                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      name="First Name"
                      label="First Name"
                      variant="outlined"
                      // onBlur={handleBlur}
                      value={firstName}
                      onClick={() => {
                        setErrorFirstName("");
                        setErrorMessage("");
                      }}
                      onChange={(e) => setFirstName(e.target.value)}
                      helperText={errorFirstName}
                      error={Boolean(errorFirstName)}
                      sx={{ mb: 3, mt: 2 }}
                      disabled={action === "view" ? true : false}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <TextField
                      fullWidth
                      size="small"
                      type="email"
                      name="text"
                      label="Last Name"
                      variant="outlined"
                      // onBlur={handleBlur}
                      value={lastName}
                      onClick={() => {
                        setErrorLastName("");
                        setErrorMessage("");
                      }}
                      onChange={(e) => setLastName(e.target.value)}
                      helperText={errorLastName}
                      error={Boolean(errorLastName)}
                      disabled={action === "view" ? true : false}
                      sx={{ mb: 3, mt: 2 }}
                    />
                  </Grid>
                </Grid>
                <TextField
                  fullWidth
                  size="small"
                  type="email"
                  name="email"
                  label="Email"
                  variant="outlined"
                  disabled={action === "view" ? true : false}
                  // onBlur={handleBlur}
                  value={email}
                  onClick={() => {
                    setErrorEmail("");
                    setErrorMessage("");
                  }}
                  onChange={(e) => setEmail(e.target.value)}
                  helperText={errorEmail}
                  error={Boolean(errorEmail)}
                  sx={{ mb: 3 }}
                />
                <TextField
                  fullWidth
                  size="small"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  label="Password"
                  variant="outlined"
                  disabled={action === "view" ? true : false}
                  // onBlur={handleBlur}
                  value={password}
                  onClick={() => {
                    setErrorPassword("");
                    setErrorMessage("");
                  }}
                  onChange={(e) => setPassword(e.target.value)}
                  helperText={errorPassword}
                  error={Boolean(errorPassword)}
                  sx={{ mb: 3 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          size="small"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {/* <img src={noImg} width={"150px"} height={"150px"}></img> */}
                  <Button disabled={action === "view" ? true : false}>
                    Upload
                  </Button>
                </div>
              </Grid>
            </Grid>

            <PhoneInput
              country={"ae"}
              disabled={action === "view" ? true : false}
              placeholder="Phone"
              inputStyle={{
                padding: "8.5px 14px 8.5px 58px",
                width: "100%",
              }}
              value={phoneNumber}
              size="small"
              countryCodeEditable={false}
              specialLabel={""}
              onChange={(value, country, e, formattedValue) => {
                setPhoneNumber(
                  `+${country.dialCode}-${value.slice(country.dialCode.length)}`
                );
                setPhoneCount(country.countryCode);
              }}
            />
            {errorPhoneNumber && (
              <Typography
                style={{ marginTop: "1rem" }}
                sx={{ color: textError }}
              >
                {errorPhoneNumber}
              </Typography>
            )}

            <FormControl
              variant="outlined"
              margin={"1"}
              style={{ width: "100%", marginTop: 28 }}
            >
              <InputLabel id="test-select-label">Role</InputLabel>
              <Select
                disabled={action === "view" ? true : false}
                style={{ width: "100%" }}
                variant="outlined"
                value={selectedRole}
                onChange={(e) => setSelectedRole(e.target.value)}
                labelId="test-select-label"
                label={"Role"}
              >
                {rolesData && rolesData.length > 0 ? (
                  rolesData.map((ele) => {
                    return <MenuItem value={ele.id}>{ele.name}</MenuItem>;
                  })
                ) : (
                  <></>
                )}
              </Select>
            </FormControl>

            <Button
              fullWidth
              variant="contained"
              color="primary"
              // onClick={handleFormSubmit}
              // loading={loading}
              sx={{ mt: 4, px: 10, backgroundColor: "#0432A3" }}
              onClick={() =>
                action == "add"
                  ? handleSubmit()
                  : action == "view"
                  ? toggle()
                  : handleEditSubmit()
              }
            >
              {action == "add"
                ? "Create User"
                : action == "view"
                ? "Close"
                : "Update User"}
            </Button>
            {errorMessage && (
              <Typography
                style={{ marginTop: "1rem" }}
                sx={{ color: textError }}
              >
                {errorMessage}
              </Typography>
            )}
          </form>
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={toggle}>
            Save changes
          </Button>
        </DialogActions> */}
      </BootstrapDialog>
    </div>
  );
}
